body {
  margin: 0;
  padding: 0;
  font-family: avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 100;
  overflow:hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'avenir';
  src: url('/public/fonts/regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'avenir';
  src: url('/public/fonts/light.ttf');
  font-weight: 200;
}
@font-face {
  font-family: 'avenir';
  src: url('/public/fonts/medium.ttf');
  font-weight: 600;
}
